<template>
  <div class="screenx row mt-5">
    <div class="offset-12 col-12 offset-xs-12 col-xs-12 offset-sm-12 col-sm-12 offset-md-1 col-md-10 offset-lg-1 col-lg-10 offset-xl-2 col-xl-8 mt-5 px-5">
      <div class="display-5">Contacts</div>
      <div class="row">
        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
          <p class="lead mt-5" v-html="sys.name + '<br />'"></p>
          <p class="lead mt-3" v-html="sys.address"></p>
          <p class="lead mt-3" v-html="sys.email + '<br />'"></p>
        </div>
        <div class="col" style="min-height:400px;">
          <form class="p-4 p-md-5 borderx rounded-3 bg-lightx mb-5">
            <div
              class="alert"
              :class="(show.mode === 'ok') ? 'alert-primary' : 'alert-danger'"
              v-if="show.alert"
            >
              <div v-html="show.message">
              </div>
            </div>

            <div class="mb-4">
              <div class="form-floating mb-3">
                <input type="text" id="request_first_name" placeholder="Enter first name" class="form-control"
                  v-model="request.first_name"
                />
                <label for="request_first_name">First Name *</label>
              </div>
              <div class="form-floating mb-3">
                <input type="text" id="request_last_name" placeholder="Enter last name" class="form-control"
                  v-model="request.last_name"
                />
                <label for="request_last_name">Last Name *</label>
              </div>
              <div class="form-floating mb-3">
                <input type="email" id="request_email" placeholder="name@example.com" class="form-control"
                  v-model="request.email"
                />
                <label for="request_email">Work Email address *</label>
              </div>
              <div class="form-floating mb-3">
                <input type="phone" id="request_phone" placeholder="(--) XXX -XXXX" class="form-control"
                  v-model="request.phone"
                />
                <label for="request_phone">Phone number</label>
              </div>
              <div class="form-floating mb-3">
                <input type="text" id="request_org" placeholder="Enter name of safety oversight organisation or company" class="form-control"
                  v-model="request.org"
                />
                <label for="request_org">Organisation *</label>
              </div>
              <div class="form-floating mb-3">
                <select id="request_org_size" placeholder="Enter staff size" class="form-control"
                  v-model="request.org_size"
                >
                  <option value="">Please select one</option>
                  <option
                    v-for="size in lists.org_sizes"
                    :key="size['.key']"
                    :value="size"
                    v-html="size"
                  ></option>
                </select>
                <label for="request_org_size">Organisation Size *</label>
              </div>
              <div class="form-floating mb-3">
                <input type="text" id="request_address" placeholder="Address" class="form-control"
                  v-model="request.address"
                />
                <label for="request_address">Address</label>
              </div>
              <div class="form-floating mb-3">
                <select id="request_state" placeholder="Country" class="form-control"
                  v-model="request.country"
                >
                  <option value="">Please select one</option>
                  <option
                    v-for="state in lists.states"
                    :key="state['.key']"
                    :value="state"
                    v-html="state"
                  ></option>
                </select>
                <label for="request_state">Country *</label>
              </div>
              <div class="form-floating mb-3">
                <select id="request_role" placeholder="Enter staff size" class="form-control"
                  v-model="request.role"
                >
                  <option disabled="" selected="" value="">Please select one</option>
                  <option
                    v-for="role in lists.roles"
                    :key="role['.key']"
                    :value="role"
                    v-html="role"
                  ></option>
                </select>
                <label for="request_role">Role *</label>
              </div>
              <div class="form-floating mb-3">
                <textarea type="text" id="request_notes" placeholder="Please let us know how we can help you" class="form-control"
                  v-model="request.notes"
                />
                <label for="request_notes">How can we help you *</label>
              </div>
              <div class="form-floating mb-3" v-if="false">
                <input type="number" id="request_count" placeholder="name@example.com" min="0" max="20" class="form-control" />
                <label for="request_count"> *</label>
              </div>
            </div>

            <div>
              <button
                type="submit"
                class="w-100 btn btn-lg mb-3 btn-outline-dark"
                @click="createRequest"
              >Submit</button>
              <a href="#"
                v-if="false"
                class="w-100 btn btn-lg btn-outline-dark mb-3"
                @click="getRandomRequest"
              >Random Request</a>

              <!--
                :class="(requestIsValid()) ? '' : 'disabled'"
              -->
            </div>
            <!----><!---->
            <!--hr class="my-4" /-->
            <div class=" text-center">
              <small class="text-muted">By clicking Submit, you agree to the terms of use.</small>
            </div>
          </form>
          </div>
        </div>
      </div>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import firebase from 'firebase/app'
export default {
  name: 'Contact',
  computed: {
    ...mapGetters({
      user: 'user',
      aviamsRequestList: 'aviamsRequestList',
      aviamsRequest: 'aviamsRequest',
      aviamsRequestSet: 'aviamsRequestSet'
    }),
  },
  methods: {
    requestIsValid () {
      let isValid = true
      isValid = (
        isValid
        && 'first_name' in this.request
        && 'last_name' in this.request
        && 'phone' in this.request
        && 'email' in this.request
        && 'address' in this.request
        && 'org' in this.request
        && 'org_size' in this.request
        && 'country' in this.request
        && 'role' in this.request
        && 'notes' in this.request
      )
      if (!isValid) {
        console.log('Request is not valid. Missing data elements.')
        return isValid
      }
      isValid = (
        isValid
        && ![null,undefined].includes(this.request.first_name)
        && ![null,undefined].includes(this.request.last_name)
        && ![null,undefined].includes(this.request.phone)
        && ![null,undefined].includes(this.request.email)
        && ![null,undefined].includes(this.request.address)
        && ![null,undefined].includes(this.request.org)
        && ![null,undefined].includes(this.request.org_size)
        && ![null,undefined].includes(this.request.country)
        && ![null,undefined].includes(this.request.role)
        && ![null,undefined].includes(this.request.notes)
      )
      isValid = (
        isValid
        && this.request.first_name.length > 0
        && this.request.last_name.length > 0
        && this.request.phone.length > 0
        && this.request.email.length > 0
        && this.request.address.length > 0
        && this.request.org.length > 0
        && this.request.org_size.length > 0
        && this.request.country.length > 0
        && this.request.role.length > 0
        && this.request.notes.length > 0
      )
      return isValid
    },
    getRequestURL (mode="") {
      let rCode = this.getRequestCode(mode)
      /*
      let url = this.sys.url
        + this.sys.endpoints.email
        + '?q=' + rCode
      console.log(url)
      */
      return rCode
    },
    getRequestCode (mode="") {
      let rUrl = ''
        + '&m=' + mode
        + '&k=' + this.sys.pKey
        + '&t=' + this.request.email
        + '&n=' + this.request.first_name + ' ' + this.request.last_name
        + '&s=' + this.sys.title
      /*
      // rUrl = rUrl.split('')
      let asciiCode = ''
      for (let i=0; i<rUrl.length; i++) {
        asciiCode += String.fromCharCode(rUrl.charCodeAt(i) - 7)
      }
      asciiCode = encodeURIComponent(asciiCode)
      // console.log(asciiCode)
      return asciiCode
      */
      return rUrl
    },
    getRandom (n=0) {
      return Math.floor((Math.random() * n) + 1);
    },
    getRandomRequest () {
      let rand = 0;
      // contact
      rand = this.getRandom(this.lists.contacts.length - 1)
      // console.log(rand, this.lists.contacts.length)
      this.request.first_name = this.lists.contacts[rand].first_name
      this.request.last_name = this.lists.contacts[rand].last_name
      this.request.phone = this.lists.contacts[rand].phone
      this.request.email = this.lists.contacts[rand].email
      // addresses
      rand = this.getRandom(this.lists.addresses.length - 1)
      // console.log(rand, this.lists.addresses.length)
      this.request.address = this.lists.addresses[rand]
      // org
      rand = this.getRandom(this.lists.orgs.length - 1)
      // console.log(rand, this.lists.orgs.length)
      this.request.org = this.lists.orgs[rand].org
      this.request.country = this.lists.orgs[rand].country
      // org size
      rand = this.getRandom(this.lists.org_sizes.length - 1)
      // console.log(rand, this.lists.org_sizes.length)
      this.request.org_size = this.lists.org_sizes[rand]
      // role
      rand = this.getRandom(this.lists.roles.length - 1)
      // console.log(rand, this.lists.roles.length)
      this.request.role = this.lists.roles[rand]
      // notes
      rand = this.getRandom(this.lists.notes.length - 1)
      // console.log(rand, this.lists.notes.length)
      this.request.notes = this.lists.notes[rand]
      console.log(this.request)
    },
    recoverRequest (e) {
      e.preventDefault()
      this.$store.dispatch('recoverAviamsRequest', this.guestSearch)
      this.show.rsvp = 'edit'
      // this.guest = { name: '', email: '', pin: '', }
    },
    updateRequest(e) {
      e.preventDefault()
      this.$store.dispatch('updateAviamsRequest', this.guestRequest)
      this.show.rsvp = 'new'
    },
    createRequest(e) {
      e.preventDefault()
      let self = this
      if (!this.requestIsValid()) { return }

      // append te key code
      let keyCode = new Date/1e3|0
      keyCode = 'AWR' + keyCode
      this.request.key = keyCode

      // self.postRequestEmail('ri')
      this.$store
          .dispatch('createAviamsRequest', this.request)
          .then (() => {
            // inform with alert
            self.postRequestAlert()
            // send email
            self.postRequestEmail('ri')
          }).catch((e) => {
            // inform with alert
            self.postRequestAlert()
            console.log('There was an error submitting request.', e)
          })
      // this.$emit('rsvpAdded', true)
      // console.log(this.request)
      this.request = this.config.nullRequest
    },
    postRequestAlert () {
      this.show.mode = 'danger'
      this.show.message = this.config.danger_message
      if (this.aviamsRequestSet) {
        this.show.mode = 'ok'
        this.show.message = this.config.ok_message
      }
      this.show.alert = true
      this.show.message = this.show.message
        .replace(/\[EMAIL\]/gi, this.sys.email)
        .replace(/\[PHONE\]/gi, this.sys.phone)
    },
    postRequestEmail (code='') {
      if ([null,undefined,''].includes(code)) { return }
      /*
      const formData = new URLSearchParams('q=' + qCode);
      let formData = new FormData();
      for (const pair of new FormData(this.request)) {
        formData.append(pair[0], pair[1])
      }
      */
      const formData = new FormData()
      let rUrl = this.sys.url
        + this.sys.endpoints.email
      let qCode = this.getRequestURL(code)
      /*
      const formData = new URLSearchParams('q=' + qCode);
      console.log('Link: ')
      console.log(rUrl)
      console.log(qCode)
      */

      for (let key in this.request) {
        formData.append(key, this.request[key])
      }
      formData.append('q', qCode)
      console.log(formData)
      // qCode = { 'q': qCode }
      // console.log(JSON.stringify(qCode))
      fetch(rUrl, {
          method: 'post',
          body: formData,
          // body: JSON.stringify(qCode),
          /*
          headers: new Headers({
            'Content-Type': 'application/json'
          })
          */
      })
      .then(response => response.json())
      .then((response) => {
        console.log(response)
      })
    },
    submit() {
      if (!this.requestIsValid()) { return }

      firebase
        .auth()
        .createUserWithEmailAndPassword(this.form.email, this.form.password)
        .then(data => {
          data.user
            .updateProfile({
              // set the username
              displayName: this.form.name
            })
            .then(() => {
              // clear the form
              this.form = { name: '', password: '', confirm_password: '', email: '' }
              // set the display
              this.error = {
                message: 'An account has been created for you. Thank you.',
                status: 'success',
              }
            });
        })
        .catch(err => {
          this.error = {
            message: err.message,
            status: 'danger'
          }
        });
    },
  },
  data () {
    return {
      sys: {
        title: 'AVIAMS Consult Official Website',
        code: 'aviams',
        name: 'AVIAMS Consult Limited',
        email: 'admin@aviamsconsult.com',
        url: 'https://aviamsconsult.com/',
        endpoints: {
          email: '38463a562635482g8309308e75e4d205/mailer/'
        },
        phone: '438 873 8143',
        address: ''
          + '15 Kormeiteh Street,<br />'
          + 'Community 18, Spintex Road<br />'
          + 'Accra, GHANA<br />'
        ,
        pKey: 'e73746f384744367a84f34ife3:A6a8EA4737fE4343Q5b4w567jU6',
      },
      show: {
        message: '',
        mode: 'ok',
        alert: false
      },
      request: {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        address: '',
        country: '',
        org: '',
        org_size: '',
        role: '',
        notes: '',
      },
      config: {
        ok_message: ''
          + 'Thank you for your interest in our services.<br>Someone from our team will process your request and get back you.'
        ,
        danger_message: ''
          + 'There was an error submitting this request. Please try again.<br>You can also send us an email [EMAIl] or call us on [PHONE].'
        ,
        nullRequest: {
          first_name: '',
          last_name: '',
          phone: '',
          email: '',
          address: '',
          country: '',
          org: '',
          org_size: '',
          role: '',
          notes: '',
        }
      },
      lists: {
        contacts: [
          {
            first_name: 'Camille', last_name: 'Norton',
            email: 'portscan@hotmail.com',
            phone: '(333) 538-9097',
          },
          {
            first_name: 'Athena', last_name: 'Porter',
            email: 'tbmaddux@outlook.com',
            phone: '(553) 437-3540',
          },
          {
            first_name: 'Elliott', last_name: 'Bradshaw',
            email: 'mosses@icloud.com',
            phone: '(728) 666-4913',
          },
          {
            first_name: 'Cadence', last_name: 'Rasmussen',
            email: 'dsowsy@live.com',
            phone: '(609) 431-3529',
          },
          {
            first_name: 'Randall', last_name: 'Lozano',
            email: 'benits@outlook.com',
            phone: '(701) 368-0131',
          },
          {
            first_name: 'Noe', last_name: 'Wheeler',
            email: 'scato@gmail.com',
            phone: '(312) 220-3921',
          },
          {
            first_name: 'Tiana', last_name: 'Villarreal',
            email: 'zwood@att.net',
            phone: '(943) 305-5198',
          },
          {
            first_name: 'Skylar', last_name: 'Gill',
            email: 'rsmartin@gmail.com',
            phone: '(936) 800-3622',
          },
          {
            first_name: 'Cynthia', last_name: 'Sherman',
            email: 'onestab@outlook.com',
            phone: '(346) 387-7501',
          },
          {
            first_name: 'Jonas', last_name: 'Stein',
            email: 'ribet@me.com',
            phone: '(730) 586-5158',
          },
          {
            first_name: 'Ashton', last_name: 'Tran',
            email: 'maradine@att.net',
            phone: '(667) 493-3974',
          },
          {
            first_name: 'Ryan', last_name: 'Alvarez',
            email: 'hellfire@comcast.net',
            phone: '(518) 757-0525',
          },
          {
            first_name: 'Esmeralda', last_name: 'Petersen',
            email: 'mxiao@yahoo.ca',
            phone: '(319) 568-6063',
          },
          {
            first_name: 'Quincy', last_name: 'Dickerson',
            email: 'bruck@live.com',
            phone: '(281) 598-6785',
          },
          {
            first_name: 'Dane', last_name: 'Curry',
            email: 'uncled@comcast.net',
            phone: '(765) 737-5350',
          },
          {
            first_name: 'Zayden', last_name: 'Duran',
            email: 'boein@comcast.net',
            phone: '(834) 588-8416',
          },
          {
            first_name: 'Luz', last_name: 'Adkins',
            email: 'shrapnull@gmail.com',
            phone: '(810) 471-4367',
          },
          {
            first_name: 'Maleah', last_name: 'Carrillo',
            email: 'bwcarty@gmail.com',
            phone: '(324) 207-4766',
          },
          {
            first_name: 'Ulises', last_name: 'Ward',
            email: 'enintend@mac.com',
            phone: '(541) 913-1384',
          },
          {
            first_name: 'Allyson', last_name: 'Golden',
            email: 'bradl@msn.com',
            phone: '(204) 356-4098',
          }
        ],
        addresses: [
          '559 Dunbar Lane Martinsville, VA 24112',
          '60 W. Military St. Danville, VA 24540',
          '81 Heritage Lane Wayne, NJ 07470',
          '374 William St. Rapid City, SD 57701',
          '168 Jennings St. Roseville, MI 48066',
          '508 Sherwood Rd. Owings Mills, MD 21117',
          '8726 Marlborough St. Madisonville, KY 42431',
          '8949 Harrison Street Annapolis, MD 21401',
          '777 Oakland Street Reisterstown, MD 21136',
          '9449 Charles Street Beloit, WI 53511',
          '40 Elizabeth Dr. Patchogue, NY 11772',
          '774 S. Schoolhouse Street Maryville, TN 37803',
          '203 Oak Dr. Wyandotte, MI 48192',
          '46 Ann St. Evans, GA 30809',
          '39 Greystone Lane Apt 40 Chaska, MN 55318',
          '251 Golf Dr. Delaware, OH 43015',
          '1 Selby Rd. Davenport, IA 52804',
          '7 Old York Ave. Conyers, GA 30012',
          '29 NE. Heritage Road East Lansing, MI 48823',
          '8423 Lawrence Dr. Bakersfield, CA 93306'     
        ],
        orgs: [
          {
            country: 'Argentina',
            org: 'Administración Nacional de Aviación Civil',
          },
          {
            country: 'Australia',
            org: 'Civil Aviation Safety Authority',
          },
          {
            country: 'Austria',
            org: 'Federal Ministry for Transport, Innovation & Technology',
          },
          {
            country: 'Belgium',
            org: 'Direction Generale Transport Aérien',
          },
          {
            country: 'Brazil',
            org: 'Departamento de Aviação Civil',
          },
          {
            country: 'Canada',
            org: 'Transport Canada Civil Aviation Authority',
          },
          {
            country: 'China',
            org: 'Civil Aviation Administration of China',
          },
          {
            country: 'Czech Republic',
            org: 'Civil Aviation Authority',
          },
          {
            country: 'Denmark',
            org: 'Danish Transport Authority',
          },
          {
            country: 'Finland',
            org: 'Finnish Transport Safety Agency',
          },
          {
            country: 'France',
            org: 'La Direction de l\'Aviation Civile',
          },
          {
            country: 'Germany',
            org: 'Civil Aviation Authority (LBA)',
          },
          {
            country: 'Iceland',
            org: 'Icelandic Civil Aviation Administration',
          },
          {
            country: 'India',
            org: 'Civil Aviation Authority',
          },
          {
            country: 'Indonesia',
            org: 'Directorate General for Civil Aviation',
          },
          {
            country: 'Ireland',
            org: 'Irish Aviation Authority',
          },
          {
            country: 'Israel',
            org: 'Civil Aviation Authority',
          },
          {
            country: 'Italy',
            org: 'Ente Nazionale per l\'Aviazione Civile',
          },
          {
            country: 'Japan',
            org: 'Japan Civil Aviation Bureau',
          },
          {
            country: 'Korea',
            org: 'Korea Office of Civil Aviation',
          },
          {
            country: 'Malaysia',
            org: 'Department of Civil Aviation Malaysia',
          },
          {
            country: 'Mexico',
            org: 'Dirección General de Aeronáutica Civil',
          },
          {
            country: 'Netherlands',
            org: 'Inspectie Leefomgeving en Transport',
          },
          {
            country: 'New Zealand',
            org: 'Civil Aviation Authority of New Zealand',
          },
          {
            country: 'Norway',
            org: 'Civil Aviation Authority Norway',
          },
          {
            country: 'Poland',
            org: 'Civil Aviation Office',
          },
          {
            country: 'Romania',
            org: 'Romanian Civil Aeronautic Authority',
          },
          {
            country: 'Russia',
            org: 'Federal Air Transport Agency (FATA)',
          },
          {
            country: 'Singapore',
            org: 'Civil Aviation Authority of Singapore',
          },
          {
            country: 'South Africa',
            org: 'Civil Aviation Authority',
          },
          {
            country: 'Spain',
            org: 'Aeropuertos Españoles y Navegación Aérea',
          },
          {
            country: 'Sweden',
            org: 'Sweden Civil Aviation Administration',
          },
          {
            country: 'Switzerland',
            org: 'Federal Office for Civil Aviation',
          },
          {
            country: 'Taiwan',
            org: 'Civil Aeronautics Administration',
          },
          {
            country: 'United Kingdom',
            org: 'Civil Aviation Authority',
          }
        ],
        notes: [
          'I would love to talk abou the safety oversight tools',
          'Hello, does AVIAMS offer services in the preparation of audits in manufacturing',
          'Hi, would like to talk to someone about implementing an on-site SMS Implementation system',
          'Hi AVIAMS, please contact me on the aforementioned details. Would like to see propects of your services for my office.',
          'Good day, Does your organisation provide training and support in Resolution of Safety Concerns',
          'Hi AIVAMS, we require assistance in implementing SSP and Annual Surveillance Programme',
          'Would need a CAA CRM tool. Are your applications suited for a CAA?',
          'We require assistance with setting up a Certification Project for our airports',
          'Hi AIVAMS, are there solutions tracking inspector training? We require a solution for monitoring trainig in our office',
          'Does your consultancy provide support on surveillance activities?'
        ],
        org_sizes: [
          '1-100',
          '101-200',
          '201-400',
          '401-1,500',
          '1,501-10,000',
          '10,001+'
        ],
        roles: [
          'Aviation Safety Inspector',
          'Aviation Safety Investigator',
          'Civil Aviation Management',
          'Aviation Service Provider',
          'Licenced Pilot',
          'Licenced Crew',
          'Aircraft Technician / Engineer',
          'Aircraft Medical',
          'Aircraft Manufacturer',
          'Dev / Engineering',
          'Military',
          'IT',
          'Sales',
          'Customer Service',
          'Marketing',
          'Finance / Legal / Ops',
          'Human Resources',
          'Strategy / Biz Dev'
        ],
        states: [
          'Afghanistan',
          'Albania',
          'Algeria',
          'Andorra',
          'Angola',
          'Antarctica',
          'Antigua and Barbuda',
          'Argentina',
          'Armenia',
          'Aruba',
          'Australia',
          'Austria',
          'Azerbaijan',
          'Bahamas',
          'Bahrain',
          'Bangladesh',
          'Barbados',
          'Belarus',
          'Belgium',
          'Belize',
          'Benin',
          'Bermuda',
          'Bhutan',
          'Bolivia',
          'Bosnia and Herzegovina',
          'Botswana',
          'Brazil',
          'Brunei Darussalam',
          'Bulgaria',
          'Burkina Faso',
          'Burundi',
          'Cambodia',
          'Cameroon',
          'Canada',
          'Cabo Verde',
          'Cayman Islands',
          'Central African Republic',
          'Chad',
          'Chile',
          'China',
          'Christmans Islands',
          'Cocos Island',
          'Colombia',
          'Comoros',
          'Congo Republic',
          'Democratic Republic of Congo',
          'Cook Islands',
          'Costa Rica',
          'Cote d\'Ivoire',
          'Croatia',
          'Cuba',
          'Cyprus',
          'Czech Republic',
          'D.P.R  of Korea',
          'Denmark',
          'Djibouti',
          'Dominica',
          'Dominican Republic',
          'East Timor',
          'Ecuador',
          'Egypt',
          'El Salvador',
          'Equatorial Guinea',
          'Eritrea',
          'Estonia',
          'Ethiopia',
          'Falkland Islands',
          'aroe Islands',
          'Fiji',
          'Finland',
          'France',
          'French Guyana',
          'French Polynesia',
          'Gabon',
          'Gambia',
          'Georgia',
          'Germany',
          'Ghana',
          'Gibraltar',
          'Greece',
          'Greenland',
          'Grenada',
          'Guadeloupe',
          'Guatemala',
          'Guinea',
          'Guinea-Bissau',
          'Guyana',
          'Haiti',
          'Holy See',
          'Honduras',
          'Hungary',
          'Iceland',
          'India',
          'Indonesia',
          'Iran',
          'Iraq',
          'Ireland',
          'Israel',
          'Italy',
          'Jamaica',
          'Japan',
          'Jordan',
          'Kazakhstan',
          'Kenya',
          'Kiribati',
          'Kuwait',
          'Kyrgyzstan',
          'Lao Peoples Democratic Republic',
          'Latvia',
          'Lebanon',
          'Lesotho',
          'Liberia',
          'Libya',
          'Liechtenstein',
          'Lithuania',
          'Luxembourg',
          'Macedonia',
          'Madagascar',
          'Malawi',
          'Malaysia',
          'Maldives',
          'Mali',
          'Malta',
          'Marocco',
          'Marshall Islands',
          'Mauritania',
          'Mauritius',
          'Mexico',
          'Micronesia',
          'Moldavia',
          'Monaco',
          'Mongolia',
          'Morocco',
          'Mozambique',
          'Myanmar',
          'Namibia',
          'Nauru',
          'Nepal',
          'Netherlands Antilles',
          'New Zealand',
          'Nicaragua',
          'Niger',
          'Nigeria',
          'Niue',
          'North Korea',
          'Norway',
          'Oman',
          'Pakistan',
          'Palau, Republic of',
          'Panama',
          'Papua New Guinea',
          'Paraguay',
          'Peru',
          'Philippines',
          'Poland',
          'Portugal',
          'Puerto Rico',
          'Qatar',
          'Korea, Republic of',
          'Moldova, Republic of',
          'Montenegro, Republic of',
          'Serbia, Republic of',
          'Yemen, Republic of',
          'Romania',
          'Russian Federation',
          'Rwanda',
          'Saint Helena',
          'Saint Kitts and Nevis',
          'Saint Lucia',
          'Samoa',
          'San Marino',
          'Sao Tome and Principe',
          'Saudi Arabia',
          'Senegal',
          'Serbia and Montenegro',
          'Seychelles',
          'Sierra Leone',
          'Singapore',
          'Slovakia',
          'Slovenia',
          'Solomon Islands',
          'Somalia',
          'South Africa',
          'Spain',
          'Sri Lanka',
          'St.Pierre and Miquelon',
          'Saint Vincent and the Grenadines',
          'Sudan',
          'Suriname',
          'Eswatini',
          'Sweden',
          'Switzerland',
          'Syrian Arab Republic',
          'Taiwan',
          'Tajikistan',
          'Tanzania',
          'Thailand',
          'Togo',
          'Tonga',
          'Trinidad and Tobago',
          'Tunisia',
          'Turkey',
          'Turkmenistan',
          'Turks and Caicos Islands',
          'Tuvalu',
          'Uganda',
          'Ukraine',
          'United Arab Emirates',
          'United Kingdom',
          'United States of America',
          'Uruguay',
          'Uzbekistan',
          'Vanuatu',
          'Vatican City',
          'Venezuela',
          'Viet Nam',
          'Virgin Islands (GB)',
          'Virgin Islands (U.S.)',
          'Wallis and Futuna Islands',
          'Yugoslavia',
          'Zambia',
          'Zimbabwe',
          'South Sudan',
          'Netherlands',
          'Other'
        ]
      }
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
